'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'

import { cn } from '@/lib/utils'

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    label?: React.ReactNode
  }
>(({ className, label, id, ...props }, ref) => (
  <div className="flex items-center gap-3">
    <CheckboxPrimitive.Root
      ref={ref}
      id={id}
      className={cn(
        'group peer flex size-6 items-center justify-center rounded-sm border border-solid border-primary-button-hover',
        'focus-visible:border-base-focus focus-visible:shadow-ring focus-visible:outline-none',
        'disabled:pointer-events-none disabled:border-form-disabled disabled:bg-form-border-quaternary',
        'disabled:data-[state=checked]:bg-primary-foreground',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn(
          'flex size-5 items-center justify-center rounded-sm border border-solid border-white',
          'data-[state=checked]:bg-form-hover',
          'group-disabled:data-[state=checked]:bg-form-disabled'
        )}
      >
        <Check className="size-3.5 stroke-[3] text-white" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {label && (
      <label
        htmlFor={id}
        className={cn(
          'peer-hover: body-md-regular cursor-pointer peer-hover:underline',
          'peer-disabled:body-md-regular peer-disabled:pointer-events-none peer-disabled:text-form-input',
          'peer-focus-visible:underline'
        )}
      >
        {label}
      </label>
    )}
  </div>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
