'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

interface DataLayerArgs {
  event?: string
  [key: string]: unknown
}

export const pushToDataLayer = ({ event, ...rest }: DataLayerArgs) => {
  const defaultArgs = {
    page_location: window?.location.href,
    page_referrer: document?.referrer,
    page_title: document?.title,
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event,
      ...rest,
      ...defaultArgs,
    })
  }
}

function DataLayer({ event = 'virtualPageView', ...rest }: DataLayerArgs = {}) {
  const path = usePathname()
  useEffect(() => {
    pushToDataLayer({
      event,
      path,
      ...rest,
    })
  }, [path, event, rest])
  return null
}

export default DataLayer
