import * as React from 'react'

import { cn } from '@/lib/utils'

const CloseIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(({ className, ...props }, ref) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-4 fill-current', className)}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_34003_63221)">
        <path d="M22.0655 16L31.1627 6.90273C32.2791 5.78636 32.2791 3.97636 31.1627 2.85909L29.1409 0.837273C28.0245 -0.279091 26.2145 -0.279091 25.0973 0.837273L16 9.93455L6.90273 0.837273C5.78636 -0.279091 3.97636 -0.279091 2.85909 0.837273L0.837273 2.85909C-0.279091 3.97545 -0.279091 5.78545 0.837273 6.90273L9.93455 16L0.837273 25.0973C-0.279091 26.2136 -0.279091 28.0236 0.837273 29.1409L2.85909 31.1627C3.97545 32.2791 5.78636 32.2791 6.90273 31.1627L16 22.0655L25.0973 31.1627C26.2136 32.2791 28.0245 32.2791 29.1409 31.1627L31.1627 29.1409C32.2791 28.0245 32.2791 26.2145 31.1627 25.0973L22.0655 16Z" />
      </g>
      <defs>
        <clipPath id="clip0_34003_63221">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})

CloseIcon.displayName = 'CloseIcon'

export { CloseIcon }
