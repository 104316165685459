import * as React from 'react'

import { cn } from '@/lib/utils'

const WarningIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(({ className, ...props }, ref) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-4 fill-current', className)}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_34011_64527)">
        <path d="M31.6399 26.223C32.6653 28.0004 31.3781 30.2223 29.33 30.2223H2.66968C0.617627 30.2223 -0.663595 27.997 0.359849 26.223L13.6902 3.11033C14.7161 1.33205 17.2857 1.33528 18.3098 3.11033L31.6399 26.223ZM16 21.4445C14.5886 21.4445 13.4445 22.5887 13.4445 24.0001C13.4445 25.4114 14.5886 26.5556 16 26.5556C17.4114 26.5556 18.5556 25.4114 18.5556 24.0001C18.5556 22.5887 17.4114 21.4445 16 21.4445ZM13.5737 12.2586L13.9858 19.8142C14.0051 20.1677 14.2975 20.4445 14.6515 20.4445H17.3485C17.7026 20.4445 17.9949 20.1677 18.0142 19.8142L18.4263 12.2586C18.4471 11.8767 18.1431 11.5556 17.7606 11.5556H14.2393C13.8569 11.5556 13.5529 11.8767 13.5737 12.2586Z" />
      </g>
      <defs>
        <clipPath id="clip0_34011_64527">
          <rect
            width="32"
            height="28.4444"
            fill="white"
            transform="translate(0 1.77783)"
          />
        </clipPath>
      </defs>
    </svg>
  )
})

WarningIcon.displayName = 'WarningIcon'

export { WarningIcon }
