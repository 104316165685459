import * as React from 'react'

import { cn } from '@/lib/utils'

const ErrorIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(({ className, ...props }, ref) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-4 fill-current md:size-5', className)}
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_34011_64380)">
        <path d="M32 16C32 24.8385 24.8359 32 16 32C7.16406 32 0 24.8385 0 16C0 7.16664 7.16406 0 16 0C24.8359 0 32 7.16664 32 16ZM16 19.2258C14.361 19.2258 13.0323 20.5545 13.0323 22.1935C13.0323 23.8326 14.361 25.1613 16 25.1613C17.639 25.1613 18.9677 23.8326 18.9677 22.1935C18.9677 20.5545 17.639 19.2258 16 19.2258ZM13.1824 8.55832L13.661 17.3325C13.6834 17.7431 14.0228 18.0645 14.434 18.0645H17.566C17.9772 18.0645 18.3166 17.7431 18.339 17.3325L18.8176 8.55832C18.8418 8.11484 18.4887 7.74194 18.0446 7.74194H13.9554C13.5112 7.74194 13.1582 8.11484 13.1824 8.55832Z" />
      </g>
      <defs>
        <clipPath id="clip0_34011_64380">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})

ErrorIcon.displayName = 'ErrorIcon'

export { ErrorIcon }
