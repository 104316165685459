'use client'

import type { ImageLoader, ImageProps } from 'next/image'
import Image from 'next/image'

import type { ImageOptions } from '@/lib/generate-image-url'
import { defaultOptions, generateImageUrl } from '@/lib/generate-image-url'

interface ContentfulImageProps extends Omit<ImageProps, 'loader'> {
  contentfulImageOptions?: Partial<ImageOptions>
}

export function ContentfulImage({
  contentfulImageOptions,
  alt,
  src,
  ...props
}: ContentfulImageProps) {
  const loader: ImageLoader = ({ src, width, quality }) => {
    return generateImageUrl(src, {
      ...defaultOptions,
      ...contentfulImageOptions,
      width,
      quality,
    })
  }

  if (!src) return null

  return <Image {...props} src={src} alt={alt} loader={loader} />
}
