'use client'

import { toast as sonnerToast } from 'sonner'

import {
  Alert,
  AlertClose,
  AlertContent,
  AlertDescription,
} from '@/components/ui/alert'

type ToastVariant = 'success' | 'error' | 'warning' | 'info'

const variantMap = {
  success: 'confirmation',
  error: 'destructive',
  warning: 'warning',
  info: 'information',
} as const

interface ToastOptions {
  duration?: number
  dismissible?: boolean
  position?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'bottom-center'
  onDismiss?: () => void
  onAutoClose?: () => void
}

const defaultOptions: ToastOptions = {
  duration: 5000,
  dismissible: true,
  position: 'top-center',
}

function toast(
  description: string,
  variant: ToastVariant = 'success',
  options: ToastOptions = {}
) {
  const mergedOptions = { ...defaultOptions, ...options }

  return sonnerToast.custom(
    (id) => (
      <Alert
        variant={variantMap[variant]}
        className="pointer-events-auto rounded-sm border-1 border-primary-foreground p-3 md:p-4 lg:w-[600px]"
      >
        <AlertContent>
          <AlertDescription>{description}</AlertDescription>
        </AlertContent>
        {mergedOptions.dismissible && (
          <AlertClose onClick={() => sonnerToast.dismiss(id)} />
        )}
      </Alert>
    ),
    mergedOptions
  )
}

export { toast, type ToastOptions }
