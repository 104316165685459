'use client'

import { useId, useState } from 'react'
import type { CheckboxCardSchema } from '@/contentful/contentful-schema-types'
import {
  documentToReactComponents,
  type Options,
} from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { useFormContext } from 'react-hook-form'

import {
  Alert,
  AlertClose,
  AlertContent,
  AlertDescription,
} from '@/components/ui/alert'
import { Checkbox } from '@/components/ui/checkbox'
import { FormControl, FormField, FormMessage } from '@/components/ui/form'
import { Link } from '@/components/ui/link'
import {
  CheckboxCard,
  CheckboxCardContent,
  CheckboxCardControl,
  CheckboxCardDescription,
  CheckboxCardError,
  CheckboxCardIcon,
  CheckboxCardMain,
  CheckboxCardText,
  CheckboxCardTitle,
} from '@/components/checkbox-card'

const renderOption: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node?.data?.uri}>{children}</Link>
    ),
  },
}

export function CheckboxCardWrapper({
  fields,
}: {
  fields: CheckboxCardSchema['fields']
}) {
  const {
    variant,
    icon,
    headline,
    content,
    checkboxLabel,
    checkboxErrorMessage,
    disabled,
  } = fields

  const uniqueId = useId()
  const [showError, setShowError] = useState(true)
  const checkboxId = `checkbox-${headline?.toLowerCase().replace(/\s+/g, '-')}-${uniqueId}`

  const form = useFormContext()
  const formFieldName = variant?.toLowerCase() || ''

  return (
    <FormField
      control={form.control}
      name={formFieldName}
      render={({ field }) => {
        const ErrorMessage = () => {
          switch (variant) {
            case 'Join':
              return (
                <CheckboxCardError>{checkboxErrorMessage}</CheckboxCardError>
              )
            case 'Manage':
              return (
                <Alert variant={'warning'}>
                  <AlertContent>
                    <AlertDescription>{checkboxErrorMessage}</AlertDescription>
                  </AlertContent>
                  <AlertClose
                    type="button"
                    onClick={() => setShowError(false)}
                  />
                </Alert>
              )
            default:
              return (
                <CheckboxCardError>
                  <FormMessage />
                </CheckboxCardError>
              )
          }
        }

        return (
          <CheckboxCard>
            <CheckboxCardMain>
              {icon && (
                <CheckboxCardIcon
                  src={`https:${icon.fields?.file?.url}`}
                  alt={icon.fields?.description || ''}
                />
              )}
              <CheckboxCardContent>
                <CheckboxCardText>
                  <CheckboxCardTitle>{headline}</CheckboxCardTitle>
                  <CheckboxCardDescription className="prose">
                    {content &&
                      documentToReactComponents(content, renderOption)}
                  </CheckboxCardDescription>
                </CheckboxCardText>
                <CheckboxCardControl>
                  <FormControl>
                    <Checkbox
                      id={checkboxId}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      disabled={disabled}
                      label={checkboxLabel}
                      onBlur={field.onBlur}
                      name={field.name}
                      ref={field.ref}
                    />
                  </FormControl>
                </CheckboxCardControl>
                {!field.value && showError && <ErrorMessage />}
              </CheckboxCardContent>
            </CheckboxCardMain>
          </CheckboxCard>
        )
      }}
    />
  )
}
