import React from 'react'
import { CircleAlert } from 'lucide-react'

import { cn } from '@/lib/utils'

import { ContentfulImage } from './contentful-image'

const CheckboxCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-col gap-4 rounded-xl bg-white px-4 pb-7 pt-6 shadow-lg',
      className
    )}
    {...props}
  >
    {children}
  </div>
))
CheckboxCard.displayName = 'CheckboxCard'

const CheckboxCardMain = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex items-start gap-4', className)} {...props}>
    {children}
  </div>
))
CheckboxCardMain.displayName = 'CheckboxCardMain'

const CheckboxCardIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    src?: string
    alt: string
  }
>(({ className, src, alt, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('relative aspect-square size-6', className)}
    {...props}
  >
    <ContentfulImage src={src} alt={alt} fill className="object-cover" />
  </div>
))
CheckboxCardIcon.displayName = 'CheckboxCardIcon'

const CheckboxCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col gap-6', className)} {...props}>
    {children}
  </div>
))
CheckboxCardContent.displayName = 'CheckboxCardContent'

const CheckboxCardText = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col gap-3', className)} {...props}>
    {children}
  </div>
))
CheckboxCardText.displayName = 'CheckboxCardText'

const CheckboxCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3 ref={ref} className={cn('body-md-bold', className)} {...props}>
    {children}
  </h3>
))
CheckboxCardTitle.displayName = 'CheckboxCardTitle'

const CheckboxCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('body-sm-regular', className)} {...props}>
    {children}
  </div>
))
CheckboxCardDescription.displayName = 'CheckboxCardDescription'

const CheckboxCardControl = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center gap-3', className)}
    {...props}
  >
    {children}
  </div>
))
CheckboxCardControl.displayName = 'CheckboxCardControl'

const CheckboxCardError = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => (
  <p ref={ref} className={cn('flex items-center gap-2', className)} {...props}>
    <CircleAlert className="size-6 fill-destructive stroke-[3] text-white" />
    <span className="body-md-regular text-destructive">{children}</span>
  </p>
))
CheckboxCardError.displayName = 'CheckboxCardError'

export {
  CheckboxCard,
  CheckboxCardContent,
  CheckboxCardControl,
  CheckboxCardDescription,
  CheckboxCardError,
  CheckboxCardIcon,
  CheckboxCardMain,
  CheckboxCardText,
  CheckboxCardTitle,
}
