import * as React from 'react'

import { cn } from '@/lib/utils'

const ConfirmationIcon = React.forwardRef<
  SVGSVGElement,
  React.HTMLAttributes<SVGSVGElement>
>(({ className, ...props }, ref) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-4 fill-current', className)}
      ref={ref}
      {...props}
    >
      <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16342 32 0 24.8366 0 16C0 7.16342 7.16342 0 16 0C24.8366 0 32 7.16342 32 16ZM14.1493 24.4719L26.0203 12.6009C26.4234 12.1978 26.4234 11.5442 26.0203 11.1411L24.5605 9.68129C24.1574 9.27813 23.5037 9.27813 23.1006 9.68129L13.4194 19.3625L8.89942 14.8425C8.49632 14.4394 7.84271 14.4394 7.43955 14.8425L5.97974 16.3023C5.57664 16.7054 5.57664 17.359 5.97974 17.7621L12.6894 24.4718C13.0926 24.875 13.7461 24.875 14.1493 24.4719Z" />
    </svg>
  )
})

ConfirmationIcon.displayName = 'ConfirmationIcon'

export { ConfirmationIcon }
