'use client'

import { useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import type {
  CheckboxCardSchema,
  LinkSchema,
  ModalSchema,
} from '@/contentful/contentful-schema-types'
import { zodResolver } from '@hookform/resolvers/zod'
import { XIcon } from 'lucide-react'
import { signIn } from 'next-auth/react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { DropdownMenuModalItem } from '@/components/ui/dropdown-menu'
import { Form } from '@/components/ui/form'
import { Link } from '@/components/ui/link'
import { toast } from '@/components/ui/sonner'
import { pushToDataLayer } from '@/components/datalayer'

import {
  memcoOptinAction,
  memcoOptoutAction,
  memcoStatusAction,
} from '../../actions'
import { CheckboxCardWrapper } from './checkbox-card'

export const TOAST_MESSAGES = {
  joinSuccess: 'You’re in! Welcome.',
  leaveSuccess:
    "You've opted out and will no longer have access to GuardianWell membership offers.",
  error: 'Something went wrong. Try again in a few minutes.',
} as const

const formSchema = z.object({
  join: z.boolean().refine((val) => val === true),
  manage: z.boolean().optional(),
  marketingConsent: z.boolean().optional(),
})

type FormValues = z.infer<typeof formSchema>

type Link = LinkSchema | undefined

const defaultTriggerComponent = (link: Link, inNav?: boolean) => {
  if (!link) return null

  const {
    fields: { href, name, type = 'button', variant },
  } = link as LinkSchema

  const defaultVariant = type === 'link' ? 'secondary-link' : 'primary'

  return (
    <Button
      key={link.sys.id}
      variant={(inNav && 'primary-accordion-link') || variant || defaultVariant}
      asChild
      className="w-fit"
    >
      <Link href={href}>{name}</Link>
    </Button>
  )
}

interface MemcoModalWrapperProps {
  content: ModalSchema
  triggerComponent?: typeof defaultTriggerComponent
  inMenu?: boolean
  inDropdown?: boolean
}

export function MemcoModalWrapper({
  content,
  triggerComponent = defaultTriggerComponent,
  inMenu,
  inDropdown,
}: MemcoModalWrapperProps) {
  const { fields } = content
  const { header, subHeader, links, contentEntities, trigger } = fields
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const path = usePathname()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      join: true,
      manage: true,
      marketingConsent: false,
    },
  })

  const {
    formState: { isSubmitting, isValid },
  } = form

  const onSubmit = async (data: FormValues) => {
    const memcoStatus = await memcoStatusAction()

    let result
    let resultMessage: string = TOAST_MESSAGES.joinSuccess

    if (memcoStatus?.data) {
      // User is leaving Memco
      if (!data.manage) {
        try {
          result = await memcoOptoutAction()
          pushToDataLayer({
            event: 'memco_leave',
            path: path,
          })
          resultMessage = TOAST_MESSAGES.leaveSuccess
        } catch {
          toast(TOAST_MESSAGES.error, 'error')
        }
      }
    } else {
      // User is joining Memco
      if (data.join) {
        try {
          result = await memcoOptinAction()
          pushToDataLayer({
            event: 'memco_join',
            path: path,
          })
        } catch {
          toast(TOAST_MESSAGES.error, 'error')
        }
      }
    }

    setOpen(false)

    if (result?.data?.success) {
      toast(resultMessage, 'success')
      await signIn('okta', { redirect: false })
    }
  }

  const handleOpenChange = (newOpenState: boolean) => {
    setOpen(newOpenState)
  }

  function renderCheckboxCardEntry(entity: CheckboxCardSchema) {
    const contentType = entity?.sys?.contentType?.sys?.id

    if (!contentType || !entity) return null

    switch (contentType) {
      case 'checkboxCard':
        return <CheckboxCardWrapper key={entity.sys.id} {...entity} />
      default:
        return null
    }
  }

  const triggerDisplay = inDropdown ? (
    <DropdownMenuModalItem>
      <DialogTrigger asChild>{triggerComponent(trigger, true)}</DialogTrigger>
    </DropdownMenuModalItem>
  ) : (
    <DialogTrigger asChild>{triggerComponent(trigger, inMenu)}</DialogTrigger>
  )

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      {triggerDisplay}
      <DialogContent
        className="max-h-[80vh] max-w-full px-5 shadow-none md:max-w-[500px] md:p-6 lg:max-w-[800px]"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex max-h-[76vh] flex-col gap-6 md:gap-7 lg:gap-8"
          >
            <DialogHeader className="gap-3">
              <div className="flex justify-between gap-3 md:gap-6">
                <DialogTitle className="heading-sm-regular flex flex-col gap-3 text-left md:heading-lg-regular">
                  {header}
                </DialogTitle>
                <DialogDescription />

                <DialogClose asChild className="items-start">
                  <Button
                    variant="primary-accordion-link"
                    className="flex size-[30px] items-center justify-center p-0"
                  >
                    <XIcon
                      aria-hidden="true"
                      className="m-2 size-[14px] shrink-0 cursor-pointer stroke-[5px]"
                    />
                    <span className="sr-only">Close</span>
                  </Button>
                </DialogClose>
              </div>
              <hr className="border-olive-400" />
            </DialogHeader>
            <div className="flex flex-col gap-7 overflow-y-scroll px-1 pb-6 md:gap-8 md:pb-7 lg:pb-8">
              {subHeader && (
                <p className="body-md-regular md:body-lg-regular lg:support-styles-intro-text-regular">
                  {subHeader}
                </p>
              )}

              {contentEntities?.map((entity) =>
                renderCheckboxCardEntry(entity as CheckboxCardSchema)
              )}
            </div>
            <div className="flex flex-col gap-3 pt-[128px] md:pt-0 lg:gap-6">
              <hr className="border-olive-400" />
              {links && links.length > 0 && (
                <div className="flex flex-col justify-end gap-4 md:flex-row lg:gap-6">
                  <DialogFooter>
                    {links.map((link) => {
                      const {
                        fields: { internalName, name, variant, href },
                      } = link!

                      switch (internalName) {
                        case 'join-modal-submit':
                          return (
                            <Button
                              key={link?.sys.id}
                              variant={variant}
                              type="submit"
                              disabled={!isValid || isSubmitting}
                            >
                              {name}
                            </Button>
                          )
                        case 'join-modal-learn':
                          return (
                            <Button
                              key={link?.sys.id}
                              role="link"
                              variant={variant}
                              type="button"
                              onClick={() => {
                                setOpen(false)
                                router.push(href)
                              }}
                            >
                              {name}
                            </Button>
                          )
                        case 'manage-modal-save':
                          return (
                            <Button
                              key={link?.sys.id}
                              variant={variant}
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {name}
                            </Button>
                          )
                        case 'manage-modal-cancel':
                          return (
                            <DialogClose key={link?.sys.id} asChild>
                              <Button variant={variant} type="button">
                                {name}
                              </Button>
                            </DialogClose>
                          )
                        default:
                          return null
                      }
                    })}
                  </DialogFooter>
                </div>
              )}
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
